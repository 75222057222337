import _react from "react";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

exports = function (t) {
  var e = {};

  function n(o) {
    if (e[o]) return e[o].exports;
    var r = e[o] = {
      i: o,
      l: !1,
      exports: {}
    };
    return t[o].call(r.exports, r, r.exports, n), r.l = !0, r.exports;
  }

  return n.m = t, n.c = e, n.d = function (t, e, o) {
    n.o(t, e) || Object.defineProperty(t, e, {
      configurable: !1,
      enumerable: !0,
      get: o
    });
  }, n.r = function (t) {
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
  }, n.n = function (t) {
    var e = t && t.__esModule ? function () {
      return t.default;
    } : function () {
      return t;
    };
    return n.d(e, "a", e), e;
  }, n.o = function (t, e) {
    return Object.prototype.hasOwnProperty.call(t, e);
  }, n.p = "", n(n.s = 4);
}([function (t, e) {
  t.exports = _react;
}, function (t, e, n) {
  t.exports = n(3)();
}, function (t, e, n) {
  "use strict";

  t.exports = "SECRET_DO_NOT_PASS_THIS_OR_YOU_WILL_BE_FIRED";
}, function (t, e, n) {
  "use strict";

  var o = n(2);

  function r() {}

  t.exports = function () {
    function t(t, e, n, r, c, i) {
      if (i !== o) {
        var u = new Error("Calling PropTypes validators directly is not supported by the `prop-types` package. Use PropTypes.checkPropTypes() to call them. Read more at http://fb.me/use-check-prop-types");
        throw u.name = "Invariant Violation", u;
      }
    }

    function e() {
      return t;
    }

    t.isRequired = t;
    var n = {
      array: t,
      bool: t,
      func: t,
      number: t,
      object: t,
      string: t,
      symbol: t,
      any: t,
      arrayOf: e,
      element: t,
      instanceOf: e,
      node: t,
      objectOf: e,
      oneOf: e,
      oneOfType: e,
      shape: e,
      exact: e
    };
    return n.checkPropTypes = r, n.PropTypes = n, n;
  };
}, function (t, e, n) {
  "use strict";

  n.r(e);
  var o = n(1),
      r = n.n(o),
      c = n(0),
      i = n.n(c);

  function u(t) {
    return (u = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (t) {
      return typeof t;
    } : function (t) {
      return t && "function" == typeof Symbol && t.constructor === Symbol && t !== Symbol.prototype ? "symbol" : typeof t;
    })(t);
  }

  function l(t, e) {
    for (var n = 0; n < e.length; n++) {
      var o = e[n];
      o.enumerable = o.enumerable || !1, o.configurable = !0, "value" in o && (o.writable = !0), Object.defineProperty(t, o.key, o);
    }
  }

  function a(t) {
    return (a = Object.setPrototypeOf ? Object.getPrototypeOf : function (t) {
      return t.__proto__ || Object.getPrototypeOf(t);
    })(t);
  }

  function s(t, e) {
    return (s = Object.setPrototypeOf || function (t, e) {
      return t.__proto__ = e, t;
    })(t, e);
  }

  function p(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }

  var f = function (t) {
    function e(t) {
      var n;
      return function (t, e) {
        if (!(t instanceof e)) throw new TypeError("Cannot call a class as a function");
      }(this || _global, e), (n = function (t, e) {
        return !e || "object" !== u(e) && "function" != typeof e ? p(t) : e;
      }(this || _global, a(e).call(this || _global, t))).state = {
        width: null
      }, n.Scrolling = n.Scrolling.bind(p(p(n))), n;
    }

    return function (t, e) {
      if ("function" != typeof e && null !== e) throw new TypeError("Super expression must either be null or a function");
      t.prototype = Object.create(e && e.prototype, {
        constructor: {
          value: t,
          writable: !0,
          configurable: !0
        }
      }), e && s(t, e);
    }(e, c["Component"]), function (t, e, n) {
      e && l(t.prototype, e), n && l(t, n);
    }(e, [{
      key: "Scrolling",
      value: function () {
        var t = document.body.scrollTop || document.documentElement.scrollTop,
            e = document.documentElement.scrollHeight - document.documentElement.clientHeight,
            n = t / e * 100;
        e > 0 ? this.setState({
          width: "".concat(n, "%")
        }) : this.setState({
          width: null
        });
      }
    }, {
      key: "componentDidMount",
      value: function () {
        try {
          window.addEventListener("scroll", (this || _global).Scrolling);
        } catch (t) {
          console.log(t);
        }
      }
    }, {
      key: "componentWillUnmount",
      value: function () {
        try {
          window.removeEventListener("scroll", (this || _global).Scrolling);
        } catch (t) {
          console.log(t);
        }
      }
    }, {
      key: "render",
      value: function () {
        var t = (this || _global).state.width,
            e = (this || _global).props,
            n = e.height,
            o = e.bgcolor;
        return i.a.createElement("div", {
          style: function (t) {
            var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "4px",
                n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "#F43059";
            return {
              margin: 0,
              padding: 0,
              position: "fixed",
              top: 0,
              zIndex: "99",
              backgroundColor: "".concat(n),
              height: "".concat(e),
              width: "".concat(t),
              transition: "".concat(t, " 0.2s ease-out")
            };
          }(t, n, o)
        });
      }
    }]), e;
  }();

  f.propTypes = {
    height: r.a.number,
    color: r.a.string.isRequired
  }, e.default = f;
}]);

export default exports;